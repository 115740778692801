/* 	Reset Styling
----------------------------------------------------------------------*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
img {
  display: block;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
* {
  outline: none;
  padding: 0px;
  margin: 0px;
}
html {
  /*min-width: 1296px;*/
  width: 100%;
  height: 100%;
  margin: 0 auto 0 auto;
  padding: 0px;
}
body {
  font-family: "HelveticaNeueLTStd-Lt", Arial, Helvetica, sans-serif;
  font-size: 10px;
  margin: 0px;
  padding: 0px;
  height: 100%;
  line-height: 14px;
}
#root {
  height: 100%;
}
h1,
h2 {
  font-family: "HelveticaNeueLTStd-Bd", Arial, Helvetica, sans-serif;
}
h3,
h4,
h5,
h6 {
  font-family: "HelveticaNeueLTStd-Md", Arial, Helvetica, sans-serif;
}
input,
button,
p,
a {
  font-family: "HelveticaNeueLTStd-Roman", Arial, Helvetica, sans-serif;
  text-decoration: none;
}
img,
video {
  border: none;
  max-width: 100%;
  height: auto;
}
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/*  Ultilities
----------------------------------------------------------------------------------------------------*/
.clear {
  clear: both;
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 0;
  height: 0;
}
.clearfix:before,
.clearfix:after {
  content: "\0020";
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 0;
  height: 0;
}
.clearfix:after {
  clear: both;
}
.clearfix {
  zoom: 1;
}

/*	Addtional Fonts
----------------------------------------------------------------------------------------------------*/

@font-face {
  font-family: "HelveticaNeueLTStd-Bd";
  src: url("fonts/HelveticaNeueLTStd-Bd.eot");
  src: url("fonts/HelveticaNeueLTStd-Bd.eot?#iefix") format("embedded-opentype"),
    url("fonts/HelveticaNeueLTStd-Bd.woff2") format("woff2"),
    url("fonts/HelveticaNeueLTStd-Bd.woff") format("woff"),
    url("fonts/HelveticaNeueLTStd-Bd.ttf") format("truetype"),
    url("fonts/HelveticaNeueLTStd-Bd.svg#HelveticaNeueLTStd-Bd") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "HelveticaNeueLTStd-HvCn";
  src: url("fonts/HelveticaNeueLTStd-HvCn.eot");
  src: url("fonts/HelveticaNeueLTStd-HvCn.eot?#iefix")
      format("embedded-opentype"),
    url("fonts/HelveticaNeueLTStd-HvCn.woff2") format("woff2"),
    url("fonts/HelveticaNeueLTStd-HvCn.woff") format("woff"),
    url("fonts/HelveticaNeueLTStd-HvCn.ttf") format("truetype"),
    url("fonts/HelveticaNeueLTStd-HvCn.svg#HelveticaNeueLTStd-HvCn")
      format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "HelveticaNeueLTStd-Lt";
  src: url("fonts/HelveticaNeueLTStd-Lt.eot");
  src: url("fonts/HelveticaNeueLTStd-Lt.eot?#iefix") format("embedded-opentype"),
    url("fonts/HelveticaNeueLTStd-Lt.woff2") format("woff2"),
    url("fonts/HelveticaNeueLTStd-Lt.woff") format("woff"),
    url("fonts/HelveticaNeueLTStd-Lt.ttf") format("truetype"),
    url("fonts/HelveticaNeueLTStd-Lt.svg#HelveticaNeueLTStd-Lt") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "HelveticaNeueLTStd-Md";
  src: url("fonts/HelveticaNeueLTStd-Md.eot");
  src: url("fonts/HelveticaNeueLTStd-Md.eot?#iefix") format("embedded-opentype"),
    url("fonts/HelveticaNeueLTStd-Md.woff2") format("woff2"),
    url("fonts/HelveticaNeueLTStd-Md.woff") format("woff"),
    url("fonts/HelveticaNeueLTStd-Md.ttf") format("truetype"),
    url("fonts/HelveticaNeueLTStd-Md.svg#HelveticaNeueLTStd-Md") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "HelveticaNeueLTStd-Roman";
  src: url("fonts/HelveticaNeueLTStd-Roman.eot");
  src: url("fonts/HelveticaNeueLTStd-Roman.eot?#iefix")
      format("embedded-opentype"),
    url("fonts/HelveticaNeueLTStd-Roman.woff2") format("woff2"),
    url("fonts/HelveticaNeueLTStd-Roman.woff") format("woff"),
    url("fonts/HelveticaNeueLTStd-Roman.ttf") format("truetype"),
    url("fonts/HelveticaNeueLTStd-Roman.svg#HelveticaNeueLTStd-Roman")
      format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "HelveticaNeueLTStd-ThIt";
  src: url("fonts/HelveticaNeueLTStd-ThIt.eot");
  src: url("fonts/HelveticaNeueLTStd-ThIt.eot?#iefix")
      format("embedded-opentype"),
    url("fonts/HelveticaNeueLTStd-ThIt.woff2") format("woff2"),
    url("fonts/HelveticaNeueLTStd-ThIt.woff") format("woff"),
    url("fonts/HelveticaNeueLTStd-ThIt.ttf") format("truetype"),
    url("fonts/HelveticaNeueLTStd-ThIt.svg#HelveticaNeueLTStd-ThIt")
      format("svg");
  font-weight: normal;
  font-style: normal;
}
